window.addEventListener("DOMContentLoaded", function () {
	const quad1 = document.querySelectorAll(".quad1 > div");
	const quad2 = document.querySelectorAll(".quad2 > div");

	const swapQuadImage = (quad) => {
		Array.from(quad).every((item, index) => {
			if (item.classList.contains("show")) {
				item.classList.remove("show");
				if (index < quad.length - 1) {
					quad[index + 1].classList.add("show");
				} else {
					quad[0].classList.add("show");
				}
				return false;
			}
			return true;
		});
	};

	window.setInterval(function () {
		swapQuadImage(quad1);
	}, 2000);
	window.setInterval(function () {
		swapQuadImage(quad2);
	}, 5000);

	const count = new countUp.CountUp("calloutNumber", 80, { enableScrollSpy: true, scrollSpyOnce: true });
	let options = {
		root: document.querySelector("#speakers"),
		rootMargin: "0px",
		threshold: 1.0,
	};
	const handleCount = () => {
		if (!count.error) {
			count.start();
			count.handleScroll();
		} else {
			console.error(count.error);
		}
	};
	let observer = new IntersectionObserver(handleCount, options);
	const menu = document.querySelector(".menu");
	const menuButton = document.querySelector(".nav__button");
	const nav = document.querySelector(".nav");

	menuButton.addEventListener("click", function (e) {
		menu.classList.toggle("show");
		e.stopPropagation();
	});

	const closeButton = document.querySelector(".nav__button.close");
	closeButton.addEventListener("click", function () {
		menu.classList.remove("show");
	});

	const clickAway = (e) => {
		if (!e.target.contains(menu) && !e.target.contains(nav) && menu.classList.contains("show")) {
			menu.classList.remove("show");
		}
	};
	window.addEventListener("click", clickAway);
});
